var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding goods-category",
    },
    [
      _c(
        "div",
        {
          staticClass: "page-header--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-header-wrap--title",
            },
            [_vm._v("商品分类")]
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleOpenFormDialog,
                  },
                },
                [_vm._v(" 新增商品分类 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("el-row", {
        staticClass: "page-filter--wrap",
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "dragTable",
          attrs: {
            "row-key": "id",
            data: _vm.tableData,
            "row-class-name": "data-row-class",
            stripe: "",
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "index",
              label: "序号",
              width: "60",
            },
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "排序",
                width: "80",
              },
            },
            [
              _c("svg-icon", {
                staticClass: "drag-handler",
                attrs: {
                  "icon-class": "drag",
                },
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "prop",
              label: "操作",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleUpdateForm(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "!text-red-600",
                        attrs: {
                          slot: "reference",
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                        slot: "reference",
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "name",
              label: "名称",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "show_in_minipg",
              label: "小程序是否展示",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref2) {
                  var row = _ref2.row
                  return [
                    _c("el-switch", {
                      on: {
                        change: function change($event) {
                          return _vm.handleUpdateShowInMinipg(row)
                        },
                      },
                      model: {
                        value: row.show_in_minipg,
                        callback: function callback($$v) {
                          _vm.$set(row, "show_in_minipg", $$v)
                        },
                        expression: "row.show_in_minipg",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              label: "位置",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref3) {
                  var row = _ref3.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.related_goods_tags
                            .map(function (item) {
                              return item.goods_tag_name
                            })
                            .join("、") || "-"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "on_sale_good_count",
              label: "上架商品数量",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              label: "创建人/创建时间",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref4) {
                  var row = _ref4.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.creator || "-") +
                        " / " +
                        _vm._s(row.create_time || "-") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              label: "修改人/修改时间",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref5) {
                  var row = _ref5.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.updater || "-") +
                        " / " +
                        _vm._s(row.update_time || "-") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.page,
          limit: _vm.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            _vm.page = $event
          },
          "update:limit": function updateLimit($event) {
            _vm.limit = $event
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formDialogTitle,
            visible: _vm.formDialogVisible,
            width: "500px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.formDialogVisible = $event
            },
            close: _vm.handleCloseFormDialog,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              attrs: {
                rules: _vm.rules,
                model: _vm.formModel,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "名称",
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "!w-[250px]",
                    model: {
                      value: _vm.formModel.name,
                      callback: function callback($$v) {
                        _vm.$set(_vm.formModel, "name", $$v)
                      },
                      expression: "formModel.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "位置",
                    prop: "related_goods_tag_ids",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-[250px]",
                      attrs: {
                        multiple: "",
                      },
                      model: {
                        value: _vm.formModel.related_goods_tag_ids,
                        callback: function callback($$v) {
                          _vm.$set(_vm.formModel, "related_goods_tag_ids", $$v)
                        },
                        expression: "formModel.related_goods_tag_ids",
                      },
                    },
                    _vm._l(_vm.tags, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "小程序是否展示",
                    prop: "show_in_minipg",
                    required: "",
                  },
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.formModel.show_in_minipg,
                      callback: function callback($$v) {
                        _vm.$set(_vm.formModel, "show_in_minipg", $$v)
                      },
                      expression: "formModel.show_in_minipg",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "text-gray-400 ml-[10px] mt-[-6px]",
                },
                [_vm._v("建议绑定商品后再打开此开关")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: _vm.handleCloseFormDialog,
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAddOrUpdate,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.textMap[_vm.status]) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.tipDialogVisible,
            width: "30%",
            close: _vm.handleCloseTipDialog,
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.tipDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("您还未创建商品分组，请先创建商品分组!")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: _vm.handleCloseTipDialog,
                  },
                },
                [_vm._v("我知道了")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: function click($event) {
                      _vm.$router.push({
                        name: "GoodsGroup",
                        params: {
                          autoAdd: true,
                        },
                      }),
                        _vm.handleCloseTipDialog()
                    },
                  },
                },
                [_vm._v("去创建")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认",
            visible: _vm.tipDialogVisible2,
            width: "30%",
            close: _vm.handleCloseTipDialog2,
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.tipDialogVisible2 = $event
            },
          },
        },
        [
          _c("span", [_vm._v("该商品分类下仍有上架商品，请先去下架!")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: _vm.handleCloseTipDialog2,
                  },
                },
                [_vm._v("我知道了")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: function click($event) {
                      _vm.$router.push({
                        path: "/goods/goods_list",
                      })
                      _vm.handleCloseTipDialog2()
                    },
                  },
                },
                [_vm._v("去下架")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认",
            visible: _vm.tipDialogVisible3,
            width: "30%",
            close: _vm.handleCloseTipDialog3,
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.tipDialogVisible3 = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确认删除该商品分类？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: _vm.handleCloseTipDialog3,
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.confirmDelete,
                  },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }