import { render, staticRenderFns } from "./OrderDetail.vue?vue&type=template&id=f991649a&scoped=true"
import script from "./OrderDetail.vue?vue&type=script&lang=js"
export * from "./OrderDetail.vue?vue&type=script&lang=js"
import style0 from "./OrderDetail.vue?vue&type=style&index=0&id=f991649a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f991649a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/Lmiy6ZAB/2/ifirst/imall/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f991649a')) {
      api.createRecord('f991649a', component.options)
    } else {
      api.reload('f991649a', component.options)
    }
    module.hot.accept("./OrderDetail.vue?vue&type=template&id=f991649a&scoped=true", function () {
      api.rerender('f991649a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall-manage/schedule-order/OrderDetail.vue"
export default component.exports