<template>
  <div class="page-padding goods-category">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">商品分类</div>
      <div>
        <el-button icon="el-icon-plus" type="primary" @click="handleOpenFormDialog"> 新增商品分类 </el-button>
      </div>
    </div>
    <el-row class="page-filter--wrap" />
    <el-table ref="dragTable" v-loading="listLoading" row-key="id" :data="tableData" row-class-name="data-row-class" stripe border>
      <el-table-column align="center" type="index" label="序号" width="60" />
      <el-table-column align="center" label="排序" width="80">
        <svg-icon class="drag-handler" icon-class="drag" />
      </el-table-column>
      <el-table-column align="center" prop="prop" label="操作" width="200">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleUpdateForm(row)"> 编辑 </el-button>
          <el-button slot="reference" type="text" class="!text-red-600" @click="handleDelete(row)"> 删除 </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="名称" />
      <el-table-column align="center" prop="show_in_minipg" label="小程序是否展示">
        <template slot-scope="{ row }">
          <el-switch v-model="row.show_in_minipg" @change="handleUpdateShowInMinipg(row)" />
        </template>
      </el-table-column>
      <el-table-column align="center" show-overflow-tooltip label="位置">
        <template slot-scope="{ row }"> {{ row.related_goods_tags.map((item) => item.goods_tag_name).join("、") || "-" }} </template>
      </el-table-column>
      <el-table-column align="center" prop="on_sale_good_count" label="上架商品数量"> </el-table-column>
      <el-table-column align="center" show-overflow-tooltip label="创建人/创建时间">
        <template slot-scope="{ row }"> {{ row.creator || "-" }} / {{ row.create_time || "-" }} </template>
      </el-table-column>
      <el-table-column align="center" show-overflow-tooltip label="修改人/修改时间">
        <template slot-scope="{ row }"> {{ row.updater || "-" }} / {{ row.update_time || "-" }} </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :page.sync="page" :limit.sync="limit" @pagination="getList" />
    <!-- 新增商品分组的弹窗 -->
    <el-dialog :title="formDialogTitle" :visible.sync="formDialogVisible" width="500px" @close="handleCloseFormDialog">
      <el-form ref="formRef" :rules="rules" :model="formModel" label-width="120px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="formModel.name" class="!w-[250px]" />
        </el-form-item>
        <el-form-item label="位置" prop="related_goods_tag_ids">
          <el-select v-model="formModel.related_goods_tag_ids" class="w-[250px]" multiple>
            <el-option v-for="item in tags" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小程序是否展示" prop="show_in_minipg" required>
          <el-switch v-model="formModel.show_in_minipg" />
        </el-form-item>
        <div class="text-gray-400 ml-[10px] mt-[-6px]">建议绑定商品后再打开此开关</div>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="handleCloseFormDialog"> 取消 </el-button>
        <el-button type="primary" @click="handleAddOrUpdate">
          {{ textMap[status] }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 无分组提示 -->
    <el-dialog title="提示" :visible.sync="tipDialogVisible" width="30%" :close="handleCloseTipDialog">
      <span>您还未创建商品分组，请先创建商品分组!</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseTipDialog">我知道了</el-button>
        <el-button type="primary" @click="$router.push({ name: 'GoodsGroup', params: { autoAdd: true } }), handleCloseTipDialog()">去创建</el-button>
      </span>
    </el-dialog>
    <!-- 仍有未下架商品提示 -->
    <el-dialog title="确认" :visible.sync="tipDialogVisible2" width="30%" :close="handleCloseTipDialog2">
      <span>该商品分类下仍有上架商品，请先去下架!</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseTipDialog2">我知道了</el-button>
        <el-button
          type="primary"
          @click="
            $router.push({ path: '/goods/goods_list' });
            handleCloseTipDialog2();
          "
          >去下架</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除确认 -->
    <el-dialog title="确认" :visible.sync="tipDialogVisible3" width="30%" :close="handleCloseTipDialog3">
      <span>确认删除该商品分类？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseTipDialog3">取消</el-button>
        <el-button type="primary" @click="confirmDelete">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as R from "ramda";
import api from "@/api";
import Sortable from "sortablejs";
import { onMounted, getCurrentInstance, ref, computed } from "vue";
import { useTable } from "@/hooks/useTable";
import Pagination from "@/components/Pagination";
import { useFetch } from "@/hooks/useFetch";
import { cloneDeep } from "lodash";
import { to } from "@/utils/common";

export default {
  name: "GoodsCategory",
  components: {
    Pagination,
  },
  setup() {
    const currentInstance = getCurrentInstance().proxy;

    const tipDialogVisible = ref(false);
    function handleCloseTipDialog() {
      tipDialogVisible.value = false;
    }

    const tipDialogVisible2 = ref(false);
    function handleCloseTipDialog2() {
      tipDialogVisible2.value = false;
    }

    const tipDialogVisible3 = ref(false);
    function handleCloseTipDialog3() {
      tipDialogVisible3.value = false;
    }

    const {
      page,
      total,
      limit,
      listLoading,
      listData: tableData,
      getList,
    } = useTable(api.goodsClassification.page, { axiosConfig: { params: { page: 1, size: 20 } } });

    const tags = ref([]);
    async function getTags() {
      const { data } = await useFetch(api.goods.goodsTagsAll);
      tags.value = data.value;
      return data.value ?? [];
    }
    /**
     * 是否有分组
     */
    async function isHasTags() {
      return !!(await getTags()).length;
    }

    // 新增/修改商品分类
    const status = ref("create");
    const initFormModal = {
      id: undefined,
      name: "",
      show_in_minipg: false,
      related_goods_tag_ids: [],
    };
    const formRef = ref(null);
    const formModel = ref(cloneDeep(initFormModal));
    const formDialogVisible = ref(false);
    const textMap = {
      create: "新增",
      update: "修改",
      view: "查看",
    };
    const rules = {
      name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      related_goods_tag_ids: [{ required: true, message: "请输入位置", trigger: "blur" }],
    };
    const formDialogTitle = computed(() => `${textMap[status.value]}商品分类`);
    async function handleShowAddCategoryDialog() {
      if (!(await isHasTags())) return (tipDialogVisible.value = true);
    }
    async function handleUpdateForm(row) {
      status.value = "update";
      row = { ...row, related_goods_tag_ids: row.related_goods_tags.map((item) => item.goods_tag_id) };
      formModel.value = R.pick(Object.keys(initFormModal), row);
      await handleOpenFormDialog();
    }
    async function handleAddOrUpdate() {
      const [err] = await to(formRef.value.validate());
      if (err === false) return;

      const { statusCode } = await useFetch(status.value === "create" ? api.goodsClassification.create : api.goodsClassification.update, {
        axiosConfig: { method: "post", data: formModel.value },
      });

      if (statusCode.value === 0) currentInstance.$message.success("操作成功");
      getList();
      handleCloseFormDialog();
    }
    async function handleOpenFormDialog() {
      if (await handleShowAddCategoryDialog()) return;
      formDialogVisible.value = true;
    }
    function handleCloseFormDialog() {
      formDialogVisible.value = false;
      resetForm();
    }
    function resetForm() {
      status.value = "create";
      formModel.value = cloneDeep(initFormModal);
    }
    function setSort(currentInstance) {
      const el = currentInstance.$refs.dragTable.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
      Sortable.create(el, {
        ghostClass: "sortable-ghost", // Class name for the drop placeholder,
        onEnd: (evt) => {
          const { oldIndex, newIndex } = evt;
          if (oldIndex === newIndex) return;
          const targetRow = tableData.value[newIndex];
          const sourceRow = tableData.value.splice(oldIndex, 1)[0];
          tableData.value.splice(newIndex, 0, sourceRow);
          sortRequest(sourceRow.id, targetRow.id, oldIndex > newIndex ? "FRONT" : "BACK");
        },
      });
    }
    async function sortRequest(source_goods_classification_id, target_goods_classification_id, front_back) {
      const data = { source_goods_classification_id, target_goods_classification_id, front_back };
      const [err] = await api.goodsClassification.move({
        method: "post",
        data,
      });
      if (err) await getList();
      else currentInstance.$notify.success("操作成功");
      currentInstance.$forceUpdate();
    }
    const currentRow = ref(null);
    async function handleDelete(row) {
      const [err, count] = await api.goodsClassification.getOnSaleGoodCount({ resource: row.id });
      if (err) return;
      if (count > 0) {
        tipDialogVisible2.value = true;
        return;
      }

      currentRow.value = row;
      tipDialogVisible3.value = true;
    }
    async function confirmDelete() {
      const [err2] = await api.goodsClassification.delete({ method: "delete", resource: currentRow.value.id });
      if (err2) return;
      currentInstance.$notify.success("操作成功");
      getList();
      handleCloseTipDialog3();
    }

    async function handleUpdateShowInMinipg(row) {
      const [err] = await api.goodsClassification.updateShowMinipg({ method: "post", data: { id: row.id, show_in_minipg: row.show_in_minipg } });
      if (err) {
        getList();
        return;
      }
      currentInstance.$notify.success("操作成功");
    }

    onMounted(() => {
      getTags();
      setSort(currentInstance);
      formRef.value = currentInstance.$refs.formRef;
      getList();
    });

    return {
      tipDialogVisible,
      handleCloseTipDialog,
      tipDialogVisible2,
      handleCloseTipDialog2,
      tipDialogVisible3,
      handleCloseTipDialog3,
      handleShowAddCategoryDialog,
      handleUpdateShowInMinipg,
      status,
      textMap,
      page,
      total,
      limit,
      tableData,
      listLoading,
      getList,
      tags,

      handleDelete,
      confirmDelete,

      formDialogTitle,
      formDialogVisible,
      formRef,
      formModel,
      handleOpenFormDialog,
      handleCloseFormDialog,
      handleUpdateForm,
      handleAddOrUpdate,
      rules,
    };
  },
};
</script>

<style lang="scss">
.goods-category {
  .sortable-ghost {
    opacity: 0.8;
    color: #fff !important;
    background: #42b983 !important;
  }
}
</style>
