import { asyncRoutes, constantRoutes } from "@/router";
import Layout from "@/layout/index.vue";

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
function hasOutletPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(() => route.meta.roles.includes("outletAdmin"));
  } else {
    return true;
  }
}
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta?.roles) {
    return roles.some((role) => route.meta.roles.includes(role));
  } else {
    return true;
  }
}

export function filterOutletRoutes(routes, roles) {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasOutletPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterOutletRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}

const state = {
  routes: [],
  addRoutes: [],
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
};

const actions = {
  async generateRoutes({ commit }, roles) {
    // return new Promise((resolve) => {
    //   let accessedRoutes;
    //   if (roles.includes("admin")) {
    //     accessedRoutes = asyncRoutes || [];
    //     accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
    //     // console.log("获取集团路由", accessedRoutes, roles);
    //   } else if (roles.includes("outletAdmin")) {
    //     accessedRoutes = filterOutletRoutes(asyncRoutes, roles);
    //     // console.log("获取门店路由", accessedRoutes, roles);
    //   } else {
    //     accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
    //     // console.log("非集团非门店");
    //   }
    //   commit("SET_ROUTES", accessedRoutes);
    //   resolve(accessedRoutes);
    // });
    let accessedRoutes;
    accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
    // 后端去掉role，所以目前没有角色控制。全都显示出来
    commit("SET_ROUTES", accessedRoutes);
    return accessedRoutes;
  },

  /**
   *后端动态读取路由
   */
  generateRoutesV2({ commit }, asyncRouter) {
    return new Promise((resolve) => {
      commit("SET_ROUTES", asyncRouter);
      resolve(asyncRouter);
    });
  },
};

/**
 *后端动态读取路由start
 */
const metaKeys = ["title", "icon"];
export function formatRoutes(routes) {
  for (const route of routes) {
    if (route.children) {
      formatRoutes(route.children);
    }
    if (route.component === "src/layout/index.vue") {
      route.component = Layout;
    } else if (route.component) {
      route.component = loadComponent(route.component);
    }

    // navbar的tag使用
    route.name = route.title;

    // sidebar的sidebarItem使用
    for (const key of metaKeys) {
      if (route[key]) {
        route.meta = route.meta || {};
        route.meta[key] = route[key];
        delete route[key];
      }
    }
  }

  return routes;
}

const loadComponent = (src) => {
  if (src.startsWith("src")) src = src.replace(/^src\/views\//, "");
  if (src.endsWith(".vue")) src = src.replace(/\.vue$/, "");

  // 此处不能全动态变量，必须有静态值，让webpack可以缩小加载范围，不然会报错找不到模块
  // https://webpack.docschina.org/api/module-methods/#dynamic-expressions-in-import
  return (resolve) => require([`@/views/${src}.vue`], resolve);
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
