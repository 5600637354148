var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.goodsDetailLoading,
          expression: "goodsDetailLoading",
        },
      ],
      staticClass: "goods-wrap",
      staticStyle: {
        background: "#fff",
      },
      attrs: {
        "element-loading-text": "加载中...",
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "sticky",
            {
              attrs: {
                "z-index": 20,
              },
            },
            [
              _c("el-page-header", {
                staticClass: "go-back",
                attrs: {
                  content: "商品信息",
                },
                on: {
                  back: _vm.goBack,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "formGoods",
          staticClass: "goods-form--wrap",
          attrs: {
            model: _vm.formGoods,
            rules: _vm.formGoodsRules,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                },
                [_vm._v("基本信息")]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 8,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品名称",
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "30",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.formGoods.name,
                          callback: function callback($$v) {
                            _vm.$set(_vm.formGoods, "name", $$v)
                          },
                          expression: "formGoods.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "采购编码",
                        prop: "supplier_goods_code",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formGoods.supplier_goods_code,
                          callback: function callback($$v) {
                            _vm.$set(_vm.formGoods, "supplier_goods_code", $$v)
                          },
                          expression: "formGoods.supplier_goods_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品类型",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100%",
                          },
                          attrs: {
                            placeholder: "请选择",
                          },
                          on: {
                            change: _vm.handleGoodsType,
                          },
                          model: {
                            value: _vm.formGoods.type,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formGoods, "type", $$v)
                            },
                            expression: "formGoods.type",
                          },
                        },
                        _vm._l(
                          _vm.getFilterGoodType(_vm.GOODS.TYPE),
                          function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: {
                                label: item.label,
                                value: item.value,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收入科目",
                        required: "",
                        prop: "income_account",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100%",
                          },
                          attrs: {
                            placeholder: "收入科目",
                          },
                          model: {
                            value: _vm.formGoods.income_account,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formGoods, "income_account", $$v)
                            },
                            expression: "formGoods.income_account",
                          },
                        },
                        _vm._l(_vm.INCOME_ACCOUNT, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: {
                              label: item.label,
                              value: item.value,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "销项税率",
                        prop: "tax_rate",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100%",
                          },
                          attrs: {
                            placeholder: "销项税率",
                          },
                          model: {
                            value: _vm.formGoods.tax_rate,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formGoods, "tax_rate", $$v)
                            },
                            expression: "formGoods.tax_rate",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: "9%",
                              value: 9,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: "13%",
                              value: 13,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品分组",
                        prop: "tag_id_list",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100%",
                          },
                          attrs: {
                            multiple: "",
                            placeholder: "请选择商品分组",
                          },
                          on: {
                            change: _vm.handleTagIdListChange,
                          },
                          model: {
                            value: _vm.formGoods.tag_id_list,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formGoods, "tag_id_list", $$v)
                            },
                            expression: "formGoods.tag_id_list",
                          },
                        },
                        _vm._l(_vm.optionsGoodsGroups, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品分类",
                        prop: "classification_id_list",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100%",
                          },
                          attrs: {
                            multiple: "",
                            placeholder: "请选择商品分类",
                          },
                          model: {
                            value: _vm.formGoods.classification_id_list,
                            callback: function callback($$v) {
                              _vm.$set(
                                _vm.formGoods,
                                "classification_id_list",
                                $$v
                              )
                            },
                            expression: "formGoods.classification_id_list",
                          },
                        },
                        _vm._l(_vm.optionsGoodsCategories, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "支付方式",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100%",
                          },
                          attrs: {
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.formGoods.pay_type,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formGoods, "pay_type", $$v)
                            },
                            expression: "formGoods.pay_type",
                          },
                        },
                        _vm._l(
                          _vm.getFilterPayType(_vm.PAY_TYPE),
                          function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: {
                                value: item.value,
                                label: item.label,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品卖点",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "60",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.formGoods.short_description,
                          callback: function callback($$v) {
                            _vm.$set(_vm.formGoods, "short_description", $$v)
                          },
                          expression: "formGoods.short_description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 16,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品图片",
                        prop: "images",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("upload-picture-with-button", {
                            attrs: {
                              "limit-count": 15,
                              "already-file": _vm.formGoods.images,
                              "cover-image-index":
                                _vm.formGoods.cover_image_index,
                              token: _vm.token,
                            },
                            on: {
                              "update:coverImageIndex":
                                function updateCoverImageIndex($event) {
                                  return _vm.$set(
                                    _vm.formGoods,
                                    "cover_image_index",
                                    $event
                                  )
                                },
                              "update:cover-image-index":
                                function updateCoverImageIndex($event) {
                                  return _vm.$set(
                                    _vm.formGoods,
                                    "cover_image_index",
                                    $event
                                  )
                                },
                              uploadSuccess: _vm.uploadDetailPicSuccess,
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "color-minor",
                            },
                            [_vm._v("建议尺寸 800*800像素，最多上传 15 张")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "主图视频",
                        prop: "video_address",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "flex-end",
                          },
                        },
                        [
                          _c("upload-video", {
                            attrs: {
                              "already-file": _vm.formGoods.video_address,
                              token: _vm.token,
                            },
                            on: {
                              uploadSuccess: _vm.uploadVideoSuccess,
                            },
                          }),
                          _vm.formGoods.video_address
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    margin: "0 15px",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                      },
                                      on: {
                                        click: function click($event) {
                                          _vm.dialogVideoCover = true
                                        },
                                      },
                                    },
                                    [_vm._v(" 编辑封面 ")]
                                  ),
                                  _c("span"),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                      },
                                      on: {
                                        click: _vm.handelDeleteVideo,
                                      },
                                    },
                                    [_vm._v(" 移除视频 ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "color-minor",
                        },
                        [
                          _vm._v(
                            "建议视频突出核心卖点，时长 9~30 秒，宽高比 16:9。请保证视频格式正确，且不超过20M"
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", {
                attrs: {
                  span: 18,
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", {
                attrs: {
                  span: 18,
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                },
                [_vm._v("价格库存")]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 22,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品规格",
                        prop: "goods_sku_info_list",
                      },
                    },
                    [
                      _c("sku", {
                        ref: "skuChild",
                        attrs: {
                          "goods-type": _vm.formGoods.type,
                          "pay-type": _vm.formGoods.pay_type,
                          specification: _vm.skuSpecification,
                          "skus-origin": _vm.skusSingleData,
                          "member-rights": _vm.formGoods.member_rights,
                          "upload-token": _vm.token,
                        },
                        on: {
                          skus: _vm.getSkusData,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "middle",
                            fn: function fn() {
                              return [
                                _c(
                                  "el-radio-group",
                                  {
                                    directives: [
                                      {
                                        name: "is-malls",
                                        rawName: "v-is-malls",
                                        value: [
                                          _vm.MALL_CODE_VALUE_MAP
                                            .taier_points_mall,
                                          _vm.MALL_CODE_VALUE_MAP
                                            .jiumaojiu_mall,
                                          _vm.MALL_CODE_VALUE_MAP
                                            .song_points_mall,
                                        ],
                                        expression:
                                          "[MALL_CODE_VALUE_MAP.taier_points_mall, MALL_CODE_VALUE_MAP.jiumaojiu_mall, MALL_CODE_VALUE_MAP.song_points_mall]",
                                      },
                                    ],
                                    model: {
                                      value: _vm.formGoods.member_rights,
                                      callback: function callback($$v) {
                                        _vm.$set(
                                          _vm.formGoods,
                                          "member_rights",
                                          $$v
                                        )
                                      },
                                      expression: "formGoods.member_rights",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: {
                                          label: true,
                                        },
                                      },
                                      [_vm._v(" 会员权益 ")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: {
                                          label: false,
                                        },
                                      },
                                      [_vm._v(" 自定义 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  attrs: {
                    span: 3,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "cut-way-wrap",
                      attrs: {
                        label: "库存扣减方式",
                        prop: "decrease_stock_occasion",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "cut-way",
                          model: {
                            value: _vm.formGoods.decrease_stock_occasion,
                            callback: function callback($$v) {
                              _vm.$set(
                                _vm.formGoods,
                                "decrease_stock_occasion",
                                $$v
                              )
                            },
                            expression: "formGoods.decrease_stock_occasion",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: "CREATING_ORDER",
                                disabled:
                                  _vm.formGoods.pay_type ===
                                  _vm.PAY_TYPE_VALUE_MAP.POINTS,
                              },
                            },
                            [
                              _vm._v(" 提交订单减库存 "),
                              _c(
                                "div",
                                {
                                  staticClass: "color-minor minor-desc",
                                },
                                [
                                  _vm._v(
                                    "买家提交订单，扣减库存数量，可能存在恶意占用库存风险"
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: "PAID",
                              },
                            },
                            [
                              _vm._v(" 支付订单减库存 "),
                              _c(
                                "div",
                                {
                                  staticClass: "color-minor minor-desc",
                                },
                                [_vm._v("该项可减少恶拍，降低超卖风险")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                },
                [_vm._v("物流信息")]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 18,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "快递运费",
                        prop: "freight",
                        rules: [
                          {
                            required: _vm.isFreightRequired(),
                            message: "请填写快递运费",
                          },
                          {
                            validator: _vm.isPositiveNumber,
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: {
                            width: "195px",
                          },
                          attrs: {
                            disabled:
                              _vm.formGoods.deliver_method !==
                              _vm.DELIVER_METHOD_VALUE_MAP.EXPRESS_DELIVERY,
                          },
                          on: {
                            change: function change(val) {
                              return (_vm.formGoods.price_break_discount_reduce =
                                val)
                            },
                          },
                          model: {
                            value: _vm.formGoods.freight,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formGoods, "freight", $$v)
                            },
                            expression: "formGoods.freight",
                          },
                        },
                        [
                          _c(
                            "template",
                            {
                              slot: "prepend",
                            },
                            [_vm._v(" ￥ ")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "满减金额",
                            prop: "price_break_discount_over",
                            rules: [
                              {
                                required: _vm.isFreightRequired(),
                                message: "请填写满减金额",
                              },
                              {
                                validator: _vm.isPositiveNumber,
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: {
                                width: "120px",
                              },
                              attrs: {
                                disabled:
                                  _vm.formGoods.deliver_method !==
                                  _vm.DELIVER_METHOD_VALUE_MAP.EXPRESS_DELIVERY,
                              },
                              model: {
                                value: _vm.formGoods.price_break_discount_over,
                                callback: function callback($$v) {
                                  _vm.$set(
                                    _vm.formGoods,
                                    "price_break_discount_over",
                                    $$v
                                  )
                                },
                                expression:
                                  "formGoods.price_break_discount_over",
                              },
                            },
                            [
                              _c(
                                "template",
                                {
                                  slot: "prepend",
                                },
                                [_vm._v(" 满 ")]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "0",
                            prop: "price_break_discount_reduce",
                            rules: [
                              {
                                required: _vm.isFreightRequired(),
                                message: "请填写优惠金额",
                              },
                              {
                                validator: _vm.isPositiveNumber,
                              },
                              {
                                validator: _vm.maxReduceValue,
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: {
                                width: "120px",
                              },
                              attrs: {
                                disabled:
                                  _vm.formGoods.deliver_method !==
                                  _vm.DELIVER_METHOD_VALUE_MAP.EXPRESS_DELIVERY,
                              },
                              model: {
                                value:
                                  _vm.formGoods.price_break_discount_reduce,
                                callback: function callback($$v) {
                                  _vm.$set(
                                    _vm.formGoods,
                                    "price_break_discount_reduce",
                                    $$v
                                  )
                                },
                                expression:
                                  "formGoods.price_break_discount_reduce",
                              },
                            },
                            [
                              _c(
                                "template",
                                {
                                  slot: "prepend",
                                },
                                [_vm._v(" 减 ")]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "配送方式",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "195px",
                          },
                          attrs: {
                            disabled:
                              _vm.formGoods.type == _vm.TYPE_VALUE_MAP.REGULAR,
                            placeholder: "请选择",
                          },
                          on: {
                            change: _vm.handleDeliverMethodChange,
                          },
                          model: {
                            value: _vm.formGoods.deliver_method,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formGoods, "deliver_method", $$v)
                            },
                            expression: "formGoods.deliver_method",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              disabled:
                                _vm.formGoods.type ===
                                _vm.TYPE_VALUE_MAP.ELECTRONIC,
                              label: "快递",
                              value:
                                _vm.DELIVER_METHOD_VALUE_MAP.EXPRESS_DELIVERY,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              disabled:
                                _vm.formGoods.type ===
                                _vm.TYPE_VALUE_MAP.ELECTRONIC,
                              label: "自提",
                              value:
                                _vm.DELIVER_METHOD_VALUE_MAP.CUSTOMER_PICK_UP,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: "无需快递",
                              value:
                                _vm.DELIVER_METHOD_VALUE_MAP.NO_NEED_TO_DELIVER,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                },
                [_vm._v("其他信息")]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 18,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "开售时间",
                        prop: "sale_status",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "cut-way",
                          on: {
                            change: _vm.handleSaleTime,
                          },
                          model: {
                            value: _vm.formGoods.sale_status,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formGoods, "sale_status", $$v)
                            },
                            expression: "formGoods.sale_status",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.SALE_STATUS_VALUE_MAP.FOR_SALE) +
                              " "
                          ),
                          _c(
                            "el-radio",
                            {
                              staticClass: "sale-time--margin",
                              attrs: {
                                label: _vm.SALE_STATUS_VALUE_MAP.FOR_SALE,
                              },
                            },
                            [
                              _vm._v(" 上架开售"),
                              _vm.formGoods.sale_status ===
                                _vm.SALE_STATUS_VALUE_MAP.FOR_SALE &&
                              _vm.formGoods.to_end_time
                                ? _c("span", [
                                    _vm._v(
                                      "（下架时间：" +
                                        _vm._s(_vm.formGoods.to_end_time) +
                                        "）"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticClass: "sale-time--margin",
                              attrs: {
                                label: _vm.SALE_STATUS_VALUE_MAP.REPLENISHING,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "150px",
                                      },
                                    },
                                    [_vm._v("上架补货中")]
                                  ),
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetime",
                                      placeholder: "预计到货时间",
                                    },
                                    model: {
                                      value:
                                        _vm.formGoods.estimated_arrival_time,
                                      callback: function callback($$v) {
                                        _vm.$set(
                                          _vm.formGoods,
                                          "estimated_arrival_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formGoods.estimated_arrival_time",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticClass: "sale-time--margin",
                              attrs: {
                                label:
                                  _vm.SALE_STATUS_VALUE_MAP.START_SELL_AFTER,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "150px",
                                      },
                                    },
                                    [_vm._v("自定义上架售卖时间")]
                                  ),
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetime",
                                      placeholder: "自定义上架售卖时间",
                                    },
                                    on: {
                                      change: _vm.handleCustomTime,
                                    },
                                    model: {
                                      value: _vm.to_sell_time,
                                      callback: function callback($$v) {
                                        _vm.to_sell_time = $$v
                                      },
                                      expression: "to_sell_time",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#000",
                                        "font-weight": "400",
                                      },
                                    },
                                    [_vm._v(" 至 ")]
                                  ),
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetime",
                                      placeholder: "不限",
                                      "picker-options": {
                                        disabledDate: _vm.disabledToEndTime,
                                      },
                                    },
                                    on: {
                                      change: _vm.handleCustomEndTime,
                                    },
                                    model: {
                                      value: _vm.to_end_time,
                                      callback: function callback($$v) {
                                        _vm.to_end_time = $$v
                                      },
                                      expression: "to_end_time",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticClass: "sale-time--margin",
                              attrs: {
                                label:
                                  _vm.SALE_STATUS_VALUE_MAP
                                    .CURRENTLY_NOT_FOR_SALE,
                              },
                            },
                            [_vm._v(" 暂不售卖，放入仓库 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "起售数量",
                        prop: "min_order_num",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: {
                            width: "195px",
                          },
                          attrs: {
                            "controls-position": "right",
                            min: 1,
                          },
                          model: {
                            value: _vm.formGoods.min_order_num,
                            callback: function callback($$v) {
                              _vm.$set(
                                _vm.formGoods,
                                "min_order_num",
                                _vm._n($$v)
                              )
                            },
                            expression: "formGoods.min_order_num",
                          },
                        },
                        [
                          _c(
                            "template",
                            {
                              slot: "append",
                            },
                            [_vm._v(" 件 ")]
                          ),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "color-minor",
                        },
                        [
                          _vm._v(
                            "起售数量超出商品库存时，买家无法购买购买该商品"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "限购",
                        prop: "order_quota",
                        rules: [
                          {
                            required: _vm.formGoods.order_quota_type !== null,
                            message: "请填写限购数量",
                          },
                          {
                            validator: _vm.isPositiveNumber,
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          on: {
                            change: _vm.handleQuotaTypeChange,
                          },
                          model: {
                            value: _vm.formGoods.order_quota_type,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formGoods, "order_quota_type", $$v)
                            },
                            expression: "formGoods.order_quota_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: "不限购",
                              value: null,
                            },
                          }),
                          _vm._l(_vm.ORDER_QUOTA_TYPE, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: {
                                label: item.label,
                                value: item.value,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-input",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-left": "10px",
                          },
                          attrs: {
                            disabled: _vm.formGoods.order_quota_type === null,
                          },
                          model: {
                            value: _vm.formGoods.order_quota,
                            callback: function callback($$v) {
                              _vm.$set(_vm.formGoods, "order_quota", $$v)
                            },
                            expression: "formGoods.order_quota",
                          },
                        },
                        [
                          _c(
                            "template",
                            {
                              slot: "append",
                            },
                            [_vm._v(" 件 ")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "is-malls",
                              rawName: "v-is-malls",
                              value: [
                                _vm.MALL_CODE_VALUE_MAP.jiumaojiu_mall,
                                _vm.MALL_CODE_VALUE_MAP.song_points_mall,
                              ],
                              expression:
                                "[MALL_CODE_VALUE_MAP.jiumaojiu_mall, MALL_CODE_VALUE_MAP.song_points_mall]",
                            },
                          ],
                          attrs: {
                            prop: "can_purchase",
                            label: "可购买人群",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              on: {
                                change: _vm.resetGradeSelect,
                              },
                              model: {
                                value: _vm.formGoods.can_purchase,
                                callback: function callback($$v) {
                                  _vm.$set(_vm.formGoods, "can_purchase", $$v)
                                },
                                expression: "formGoods.can_purchase",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "所有人",
                                  value: "ALL",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "指定会员等级",
                                  value: "ASSIGNED_GRADE",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.formGoods.can_purchase === "ASSIGNED_GRADE"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                required: "",
                                "label-width": "80px",
                                prop: "grade_id_list",
                                label: "会员等级",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    multiple: "",
                                    clearable: "",
                                    "collapse-tags": "",
                                  },
                                  model: {
                                    value: _vm.formGoods.grade_id_list,
                                    callback: function callback($$v) {
                                      _vm.$set(
                                        _vm.formGoods,
                                        "grade_id_list",
                                        $$v
                                      )
                                    },
                                    expression: "formGoods.grade_id_list",
                                  },
                                },
                                _vm._l(_vm.memberGradeList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.level_name,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticStyle: {
                "z-index": "2",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                  staticStyle: {
                    "margin-bottom": "0",
                  },
                },
                [_vm._v("图文详情")]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 18,
                  },
                },
                [
                  _c("editor", {
                    attrs: {
                      "already-content": _vm.formGoods.detail,
                      token: _vm.token,
                    },
                    on: {
                      editorContent: _vm.getEditorContent,
                    },
                  }),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: _vm.goBack,
                          },
                        },
                        [_vm._v(" 返回 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.btnLoading,
                            type: "primary",
                          },
                          on: {
                            click: function click($event) {
                              return _vm.submitGoodsForm("formGoods")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.nativeSpuCode === "" ? "确定" : "修改"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑视频",
            top: "2vh",
            visible: _vm.dialogVideoCover,
            width: "600px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogVideoCover = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "编辑封面",
                    prop: "video_cover_image_address",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("upload-picture", {
                        attrs: {
                          "limit-count": 1,
                          "already-file":
                            _vm.formGoods.video_cover_image_address,
                        },
                        on: {
                          uploadSuccess: _vm.uploadVidoeCoverSuccess,
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "color-minor",
                        },
                        [
                          _vm._v(
                            "建议尺寸 800*800像素，如不添加封面，系统默认截取视频第一个画面为封面。"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogVideoCover = false
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogVideoCover = false
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }